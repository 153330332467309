import React, { useState } from "react";
import Headerbackground from '../assets/header-bg.jpg'
import logo from '../assets/psglogo-sm.png'


function ScrollHeader() {

    const [navbar, setNavbar] = useState(false)

    const changeNavbar=()=>{
        if(window.scrollY >= 300) {
                setNavbar(true) 
            } else {
                setNavbar(false);
            }
    
        }

    window.addEventListener('scroll', changeNavbar);

    return(
        <div>
            <div className={navbar?"header-fixed active":"header-fixed"}>
                    <div className="header-top">
                    {/* <div className={navbar?'navbar active':'navbar'}> */}
                        <div className="container">
                            <nav className='nav-primary'>
                                <ul id='menu-main-menu-1' className="menu">
                                    
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-128">
                                    
                                    </li>
                                    
                                </ul>
                            </nav>
                        </div>
                        <div className="container text-center" style={{backgroundImage:`url(${Headerbackground})`}}>
                            <div className="header-info-wrapper hidden-xxs">
                                <div className="header-info pull-left">
                                    <span>Pre-Orders</span>
                                    <p>Pickup @ 415 N 9th St.
                                    </p>
                                </div>
                            </div>
                            <a href='https://www.poindexterbbq.com' className="site-branding-small">
                                <img className='img-responsive' src={`${logo}`}></img>
                            </a>
                            <div className="header-info-wrapper hidden-xxs">
                                <div className="header-info pull-right">
                                    <span>Contact Us</span>
                                    <p>
                                    <a className="green-btn header-btn" href="mailto: meat@poindexterbbq.com">Email</a>
                                    </p>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ScrollHeader