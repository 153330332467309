import React from 'react'

function ScheduleInfo ( ) {
    return(
        <div className='schedule-container'>

                <header className="masthead masthead--slideshow home-masthead" id="masthead">
    	            <div className="banner-desc">
                    <br />
                    <h1><span className="title-top"></span>
                        <br />
                       
                
                        <span className="title-bottom"></span>
        
                        </h1>
                    </div>
                </header>
            <div className='schedule-info'>
           
        
                          
                <p className="menus-cta">
                <div id="header_file" include-html="header.html"></div>
                </p>
            </div>
        </div>
    )
}

export default ScheduleInfo