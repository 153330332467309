import React from "react";
import Headerbackground from '../assets/header-bg.jpg'
import logo from '../assets/psglogo-sm.png'

function PageHeader() {
    return(
        <div>
            <header className='page-header' id='page-header'>
                <div className="header-top">
                    <div className="container">
                        <nav className="nav-primary">
                            <ul id='menu-main-menu-1' className="menu">
                                
                                
                                
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="header-bottom" style={{backgroundImage:`url(${Headerbackground})`}} >
                    <div className="container text-center">
                        <div className="header-info-wrapper hidden-xxs hidden-xs">
                            <div className="header-info pull-left">
                                <span>Pick-up</span>
                                <p>415 N 9th St.
                                </p>
                            </div>
                        </div>
                        <a href="" className="site-branding">
                            <img className="img-responsive img-pad-bottom" src='./assets/2poindexterMid.png'></img>
                        </a>
                        <div className="header-info-wrapper hidden-xxs hidden-xs">
                            <div className="header-info header-info-right pull-right">
                                <span className="header-info-title"> Contact Us!</span>
                                <div className="header-btns">
                                
                                    <a className="green-btn header-btn" href="mailto: meat@poindexterbbq.com">Email</a>
                                    
                                </div>
                            </div>
                        </div>                       
                    </div>
                </div>
            </header>
        </div>
    )
}

export default PageHeader