import React from 'react';

function MenuParagraph() {
    return(
        <div className="menu-intro">
            <div className="container">

                <h2>"Poindexter BBQ" <span>in Downtown Baton Rouge</span></h2>
                <div className="menu-intro-content">

                    <div className="row hidden-xs">

                        <div className="col-sm-6">
                            <p>Baton Rouge BBQ Food Truck.</p>
                        </div>
                        <div className="col-sm-6">
                            <p>Our menu</p>
                        </div>

                    </div>
                    <div className="row"></div>

                </div>

            </div>
        </div>    
    )
}

export default MenuParagraph