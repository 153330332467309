import React from 'react';
import background from '../assets/bricks.jpg'
import sandwichwrap from '../assets/sandwichwrap.jpg'

function IntroParagraph() {
    return(
    <section className="page-content"  style={{backgroundImage:`url(${background})`}}>
        <div className="company-intro">
            <div className="container">

                <div className="company-intro-content">
                    <div className="company-intro-img visible-sm visible-xs visible-xxs" style={{backgroundImage:`url(${sandwichwrap})`}}></div>
                    <div className="company-intro-desc">
                        <h2>Welcome to Poindexter BBQ</h2>
                        <p></p>
                        <p>We will be doing either drive thru lunch or pre-orders. The most up to date information will be here on top of this page. 
                            </p>
                            <p>
                                If you catch us sold out, please try the following week and we're really sorry. Our style is Texas, mostly salt and pepper and we do our best serve great bbq so you come back. 

                                <p>Again, if we are sold out, we are very, very, very sorry and hope you try us again. </p>
                        </p>
                        <p>Our cooking facility is located down town at 415 N 9th St and this where you will pickup. </p>
                        <p>-Poindexter</p>
                        <a href='https://sohungry.net/?id=71' className="green-btn">MENU</a>
                        <div className="company-intro-img hidden-sm hidden-xs 
                        hidden-xxs" styles="background-image: url('');"></div>
                    </div>
                    <div class="company-intro-img hidden-sm hidden-xs hidden-xxs" style={{backgroundImage:`url(${sandwichwrap})`}}></div>
                </div>
            </div>
        </div>
        <div className='menu-intro'></div>
        <div className="products-wrapper"></div>
    </section>
    )
}

export default IntroParagraph